<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">MERCH</h2></div>
            <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex column gaps">
            <div class="box flex gaps">
              <el-input class="box grow" ref="search" v-model="search"></el-input>
            </div>
            <div class="box">
              <vue-scroll class="scroll-area flex" :settings="settings" :style="{height: scrollHeight + 'px'}">
                <div class="box grow">
                  <app-snack-row
                    :item="s"
                    :count="getTimes(s._id)"
                    @inputCount="inputCount"
                    v-for="(s, index) in filtered" :key="index + '-merch'"></app-snack-row>
                </div>
              </vue-scroll>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppSnackRow from './components/SnackRow'

export default {
  name: 'SaleMerch',

  components: {
    AppSnackRow
  },

  props: {
    members: {
      type: Array,
      default: function () {
        return []
      }
    },
    clearInputHome: {
      type: Boolean,
      default: false
    },
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      dataMerch: [],
      search: '',
      settings: {
        maxScrollbarLength: 60
      }
    }
  },
  computed: {
    ...mapState({
      innerHeight: state => state.nav.innerHeight
    }),
    ...mapGetters([
      'merch'
    ]),
    filtered () {
      return this.dataMerch.filter(item => {
        return this.search.length < 2 ||
       (item.hasOwnProperty('name') && item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('barcode') && item.barcode.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) ||
       (item.hasOwnProperty('description') && item.description.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
      })
    },
    scrollHeight () {
      return this.innerHeight - 395
    }
  },

  methods: {
    ...mapActions(['createBasketItemSnackDefault', 'editBasketItem']),
    getTimes (id) {
      let basket = this.basket.find(b => b.category === 'Merch' && b.itemId === id)
      return basket ? basket.times : 0
    },
    inputCount ({ item, e }) {
      let coincidence = this.basket.find(b => b.category === 'Merch' && b.itemId === item._id)
      if (coincidence) {
        this.editBasketItem({
          basketId: coincidence.basketId,
          content: {
            times: e
          }
        })
      } else {
        this.createBasketItemSnackDefault({ item, times: e, category: 'Merch' })
      }
    }
  },

  activated: function () {
    if (JSON.parse(localStorage.getItem('cursor'))) {
      this.$refs.search.focus()
    }
    this.search = ''
    let inBasket = this.merch.filter(t => {
      return this.basket.some(el => el.itemId === t._id && el.times > 0)
    })
    let outBasket = this.merch.filter(t => {
      return !this.basket.some(el => el.itemId === t._id && el.times > 0)
    })
    this.dataMerch = [...inBasket, ...outBasket]
  },
  watch: {
    clearInputHome: function (val, oldVal) {
      this.search = ''
      if (JSON.parse(localStorage.getItem('cursor'))) {
        this.$refs.search.focus()
      }
    }
  }
}
</script>
