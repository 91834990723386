<template>
  <div class="box flex">
      <div class="box grow addons-panel clickable col-4 flex column gaps">
        <div class="box card-base m-5">
            <div class="p-10 flex column">
                <h4 class="box center pb-10">{{item.name}}</h4>
                <div class="flex">
                    <div class="box self-flex-end m-0 font-size-13 right">{{item.cost}} AED</div>
                </div>
            </div>
        </div>
      </div>
      <div class="box grow flex">
        <div class="box center">
            <el-input-number
              v-model="value"
              :step="1" :min="0"
              @change="$emit('inputCount', { item, e: $event })"></el-input-number>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'SnackRow',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },
    count: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },

  data () {
    return {}
  },

  computed: {
    value: {
      get () {
        return this.count
      },
      set (newItem) {
        return newItem
      }
    }
  }
}
</script>
